/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useContext } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { AppContext } from "components/Context/AppContext"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"

import GET_CART from "queries/get-cart"
import CLEAR_CART_MUTATION from "mutations/clear-cart"
import { getFormattedCart } from "utils/functions"

const ConfirmationContent = () => {
  const [cart, setCart] = useContext(AppContext)

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))

      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Update Cart Mutation.
  const [clearCart, { loading: clearCartProcessing }] = useMutation(
    CLEAR_CART_MUTATION,
    {
      onCompleted: () => {
        refetch()
      },
      onError: error => {
        if (error) {
          console.warn(error.graphQLErrors[0].message)
        }
      },
    }
  )

  // Clear the entire cart.
  const handleClearCart = () => {
    if (clearCartProcessing) {
      return
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: v4(),
          all: true,
        },
      },
    })
  }

  useEffect(() => {
    handleClearCart()
  }, [])

  return (
    <div className="order-confirmation__content">
      <div className="row justify-content-center">
        <div className="col-xl-5">
          <PageSubHeader title="Twoje zamówienie zostało złożone!" />
          <p>
            Zamówienie zostało złożone i będzie zrealizowane najszybciej jak to
            możliwe. Za chwilę otrzymasz <i>potwierdzenie mailowe</i> . W razie
            pytań bądź wątpliwości możesz się z nami skontaktować telefonicznie
            pod numerem <a href="tel:+48511505050">511-505-050</a>, bądź
            wysyłając wiadomość email na adres{" "}
            <a href="mailto:kontakt@memtech.pl">kontakt@memtech.pl</a>.
          </p>
          <p>
            W przypadku wybrania płatności przelewem tradycjnym prosimy o
            uregulowanie należności na poniższe dane:
          </p>
          <address>
            Numer konta bankowego:
            <strong>19 1140 2004 0000 3302 7774 2399</strong>
            <br />
            MEMTECH Sp. z o.o. Sp. k.
            <br />
            ul. Doroszewskiego 7
            <br />
            01-318 Warszawa
            <br />
            NIP: 726-265-53-49
          </address>
          <strong>Dziękujemy za wybranie naszego sklepu.</strong>
          <Button type="link" to="/">
            Strona główna
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationContent
